import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import {AiOutlineSend, AiOutlineInfoCircle, AiOutlineMail} from 'react-icons/ai';
import {BiMap} from 'react-icons/bi';
import { FaPhoneAlt } from "react-icons/fa";
import './contact.css';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_wjae2jk', 'template_dnw91ff', form.current, '9VxUcLyy8iGBA5-Sj')
      .then(
        (result) => {
          alert('Your message has been sent');
        },
        (error) => {
          alert('Your message has not been sent');
        }
      );

    e.target.reset();
  };

  return (
    <section className='contact section container' id='contact'>
      <h2 className='section__title'>Will you attend?</h2>
      <p className='section__subtitle'>We look forward to celebrating with you kindly RSVP by 27th of July 2024</p>
      <div className='contact__container grid contact__card'>
        <div className='contact__info'>
          <p>
            <AiOutlineInfoCircle className='contact__card-icon'/> 
             Bed and breakfast accommodations are open for booking at the Garryvoe Hotel.
            Remember to specify that you're booking rooms for Patrick and Jasmine's wedding
            to avail of discount. Hotel details are provided below.
          </p>
          <p>
            <BiMap className='contact__card-icon'/>
            Garryvoe,<br />
            Ballylongane,<br />
            East Cork,<br />
            P25 DR94<br /><br />
            <span>Click <a href='https://www.google.com/maps/dir//Garryvoe,+Ballylongane,+East,+Co.+Cork,+P25+DR94/@51.8589904,-8.0865816,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x48437a748a6fede5:0xf8bb22ae105c4488!2m2!1d-8.0041814!2d51.8590192?entry=ttu'><b><u>here</u></b></a> for directions.</span>
          </p>
          <p>
            <FaPhoneAlt className='contact__card-icon'/>
            <a href="tel:+353214646718" target="_blank" rel="noopener noreferrer"><u>+353 21 4646718</u></a>
          </p>
          <p>
            <AiOutlineMail className='contact__card-icon'/>
            <a href="mailto:res@garryvoehotel.com" target="_blank" rel="noopener noreferrer"><u>res@garryvoehotel.com</u></a>
          </p>
        </div>
        <div className='contact__content'>
          <form className='contact__form' ref={form} onSubmit={sendEmail}>
            <div className='contact__form-div'>
              <label className='contact__form-tag main__label'>Full Name</label>
              <input type='text' name='name' className='contact__form-input' placeholder='Your name' required />
            </div>
            <div className='contact__form-div'>
              <label className="main__label" htmlFor='attendanceDay1'>Will you be attending?</label>
              <div className='conatct__select'>
                <input type="radio" id="yes" name="attendance" value="yes"></input>
                <label className='extra__gap' for="yes">Yes</label>
                <input type="radio" id="no" name="attendance" value="no"></input>
                <label for="no">No</label>
              </div>
            </div>
            <div className='contact__form-div'>
              <label className='contact__form-tag main__label'>Will you be bringing +1?</label>
              <div className='conatct__select'>
                <input type="radio" id="yes" name="plusone" value="yes"></input>
                <label className='extra__gap' for="yes">Yes</label>
                <input type="radio" id="no" name="plusone" value="no"></input>
                <label for="no">No</label>
              </div>
            </div>
            <div className='contact__form-div'>
              <label htmlFor='attendanceDay1'  className="main__label">Will you be attending day 2?</label>
              <div className='conatct__select'>
                <input type="radio" id="yes" name="attendancetwo" value="yes"></input>
                <label className='extra__gap' for="yes">Yes</label>
                <input type="radio" id="no" name="attendancetwo" value="no"></input>
                <label for="no">No</label>
              </div>
            </div>
            <div className='contact__message'>
              <p className='section__small'>
                <AiOutlineInfoCircle className='section__small-icon'/> 
                If you have any allergies or specific dietary needs, please inform us.<br />
                Additionally, feel free to reach out if you have any questions.<br />
                Thank you!</p>
              <label className='contact__form-tag main__label'>Message</label>
              <textarea name='message' cols='30' rows='10' className='contact__form-input' placeholder='Your message'></textarea>
            </div>
            <button type='submit' className='button button--flex'>
              Send <AiOutlineSend className='send-icon' />
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
