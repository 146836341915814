import React from 'react'

const Footer = () => {
  return (
    <div className='footer__container'>
        <div className='footer__inner container grid'>
            <div className='copyrights'>
                <p>Copyright© {(new Date().getFullYear())} <a href="https://web-brickdesign.com/" target='_blank' rel="noopener noreferrer"><u>Web-Brick Design</u></a></p>
            </div>
            <div className='developed'>
                <p>Designed & Developed by <b>Jasmine K</b></p>
            </div>
        </div>
    </div>
  )
}

export default Footer