import React from 'react';
import './home.css';
import {AiOutlineArrowDown} from 'react-icons/ai';
import bannerImage from '../../assets/banner.jpg';
import bannerImageImageSmall from '../../assets/banner-mobile.jpg';

const Home = () => {
  return (
    <section className='home section' id='home'>
      <div className='home__container'>
        <picture>
          <source srcSet={bannerImageImageSmall} media='(max-width: 450px)' />
            <source srcSet={bannerImage} media='(min-width: 451px)' />
            <source srcSet={bannerImage} />
          <img src={bannerImage} alt='homepage banner' title='homepage banner' className='home__banner'  width="1500" height="800" />
        </picture>
        <div class='banner__inner'>
          <h1 className='banner__title'>Patrick & Jasmine</h1>
          <h3 className='banner__subtitle'>We are getting married on Sunday <u>18.08.2024</u></h3>
          <div className='buttons_section'>
          <button className='home__scroll'>
            <a href='#about' className='home__scoll-button button--flex'>
              <span className='home__scroll-label'>About Us</span>
                <AiOutlineArrowDown className="home__scroll-arrow" />
            </a>
          </button>
          <button className='home__scroll'>
            <a href='#contact' className='home__scoll-button button--flex'>
              <span className='home__scroll-label'>RSVP</span>
              <AiOutlineArrowDown className="home__scroll-arrow" />
            </a>
          </button>
          <button className='home__scroll'>
            <a href='#wedding-info' className='home__scoll-button button--flex'>
              <span className='home__scroll-label'>Wedding Info</span>
              <AiOutlineArrowDown className="home__scroll-arrow" />
            </a>
          </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Home