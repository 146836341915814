import './App.css';
import Home from './components/home/Home';
import About from './components/about/About';
import Memories from './components/about/Memories';
import WeddingInfo from './components/weddinginfo/WeddingInfo';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';

function App() {
  return (
    <div>
      <main className='main'>
        <Home />
        <About />
        <Memories />
        <WeddingInfo />
        <Contact />
        <Footer />
      </main>
    </div>
  );
}

export default App;
