import React from 'react';
import './about.css';
import aboutImageSmall from '../../assets/Wedding-gallery-mobile-coloured.jpg';
import aboutImageMedium from '../../assets/Wedding-gallery-laptop-coloured.jpg';
import aboutImageLarge from '../../assets/Wedding-gallery-laptop-coloured.jpg';

const Memories = () => {
  return (
    <section className='memories section' id='memories'>
        <h2 className='section__title'>Memories</h2>
        <h3 className='section__subtitle'>Capturing moments from our recent past.</h3>
          <div className='memories__container'>
            <picture>
                <source srcSet={aboutImageSmall} media='(max-width: 450px)' />
                <source srcSet={aboutImageMedium} media='(min-width: 451px)' />
                <source srcSet={aboutImageLarge} />
                <img src={aboutImageLarge} alt='memories gallery' title='memories gallery' className='memories__img'  width="1600" height="750" />
          </picture>
        </div>
    </section>
  )
}

export default Memories