import React, { useState } from 'react';
import './weddinginfo.css';
import { FaChurch, FaMapMarkerAlt, FaClock, FaDownload } from "react-icons/fa";
import {VscCalendar} from 'react-icons/vsc';
import {GiPartyPopper, GiFireworkRocket, GiPartyFlags} from "react-icons/gi";
import Agenda from '../../assets/wedding-day-agenda.pdf';


const WeddingInfo = () => {
    const [toggleState] = useState(1);

  return (
    <section className='about section container' id='wedding-info'>
        <h2 className='section__title'>Wedding Day Agenda</h2>
        <a download="" href={Agenda} className='download__button icon__aligment'>
            Download Wedding Day Timetable <FaDownload />
        </a>
        <div className='wedding-info__container container'>
            <div className='wedding-info__tabs'>
                <div className={
                    toggleState === 1 ? 'wedding-info__button button--flex wedding-info__active'
                    : 'wedding-info__button button--flex' }
                >
                     <FaChurch className='wedding-info__icon'/>
                    {""} Wedding Day
                </div>
            </div>
            <div className='wedding-info__sections'>
                <div className={
                    toggleState === 1 ? 'wedding-info__content wedding-info__content-active'
                    : 'wedding-info__content'} >
                    <div className='wedding-info__data'>
                        <div>
                            <h3 className='wedding-info__title'>Wedding Ceremony</h3>
                              <span className='wedding-info__subtitle'>
                                  <FaMapMarkerAlt /> Garryvoe Hotel - Second floor balcony.
                            </span>
                            <div className='wedding-info__calendar icon__aligment'>
                                <VscCalendar /> 18.08.2024 - <FaClock /> 2:30 pm.
                            </div>
                        </div>
                        <div>
                            <span className='wedding-info__rounder'></span>
                            <span className='wedding-info__line'></span>
                        </div>
                    </div>
                    <div className='wedding-info__data'>
                        <div></div>
                        <div>
                            <span className='wedding-info__rounder'></span>
                            <span className='wedding-info__line'></span>
                        </div>
                        <div>
                            <h3 className='wedding-info__title'>Drinks Reception</h3>
                              <span className='wedding-info__subtitle'>
                                  <FaMapMarkerAlt /> Garryvoe Hotel - Samphire Restaurant.
                            </span>
                            <div className='wedding-info__calendar icon__aligment'>
                                <VscCalendar /> 18.08.2024 - <FaClock /> 4:00 pm.
                            </div>
                        </div>
                    </div>
                    <div className='wedding-info__data'>
                        <div>
                            <h3 className='wedding-info__title'>Dinner</h3>
                              <span className='wedding-info__subtitle'>
                                  <FaMapMarkerAlt /> Garryvoe Hotel - Ballroom.
                            </span>
                            <div className='wedding-info__calendar icon__aligment'>
                                <VscCalendar /> 18.08.2024 - <FaClock /> 6:00 pm.
                            </div>
                        </div>
                        <div>
                            <span className='wedding-info__rounder'></span>
                            <span className='wedding-info__line'></span>
                        </div>
                    </div>
                    <div className='wedding-info__data'>
                        <div></div>
                        <div>
                            <span className='wedding-info__rounder'></span>
                            <span className='wedding-info__line'></span>
                        </div>
                        <div>
                            <h3 className='wedding-info__title'>Surprise <GiFireworkRocket /></h3>
                              <span className='wedding-info__subtitle'>
                                  <FaMapMarkerAlt /> Garryvoe Hotel - Outside.
                            </span>
                            <div className='wedding-info__calendar icon__aligment'>
                                <VscCalendar /> 18.08.2024 - <FaClock /> 10:30 pm.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div className='wedding-info__container container'>
            <div className='wedding-info__tabs'>
                <div className={
                    toggleState === 1 ? 'wedding-info__button button--flex wedding-info__active'
                    : 'wedding-info__button button--flex' }
                >
                     <GiPartyPopper className='wedding-info__icon'/>
                    {""} Wedding Day 2
                </div>
            </div>
            <div className='wedding-info__sections'>
                <div className={
                    toggleState === 1 ? 'wedding-info__content wedding-info__content-active'
                    : 'wedding-info__content'} >
                    <div className='wedding-info__data'>
                        <div>
                            <h3 className='wedding-info__title'>Food & Drinks</h3>
                              <span className='wedding-info__subtitle'>
                                  <FaMapMarkerAlt /> Garryvoe Hotel - The Cave Pub.
                            </span>
                            <div className='wedding-info__calendar icon__aligment'>
                                <VscCalendar /> 19.08.2024 - <FaClock /> 5:00 pm.
                            </div>
                        </div>
                        <div>
                            <span className='wedding-info__rounder'></span>
                            <span className='wedding-info__line'></span>
                        </div>
                    </div>
                    <div className='wedding-info__data'>
                        <div></div>
                        <div>
                            <span className='wedding-info__rounder'></span>
                            <span className='wedding-info__line'></span>
                        </div>
                        <div>
                            <h3 className='wedding-info__title'>Games</h3>
                              <span className='wedding-info__subtitle'>
                                  <FaMapMarkerAlt /> Garryvoe Hotel - The Cave Pub.
                            </span>
                            <div className='wedding-info__calendar icon__aligment'>
                                <VscCalendar /> 19.08.2024 - <FaClock /> 6:30 pm.
                            </div>
                        </div>
                    </div>
                    <div className='wedding-info__data'>
                        <div>
                            <h3 className='wedding-info__title'>Party <GiPartyFlags /></h3>
                              <span className='wedding-info__subtitle'>
                                  <FaMapMarkerAlt /> Garryvoe Hotel - The Cave Pub.
                            </span>
                            <div className='wedding-info__calendar icon__aligment'>
                                <VscCalendar /> 19.08.2024 - <FaClock /> 8:00 pm.
                            </div>
                        </div>
                        <div>
                            <span className='wedding-info__rounder'></span>
                            <span className='wedding-info__line'></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default WeddingInfo