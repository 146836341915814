import React from 'react';
import './about.css';
import aboutImage from '../../assets/about1.jpg'
import {FaGrimace} from "react-icons/fa";
import { MdOutlineSportsHockey } from "react-icons/md";

const About = () => {
  return (
    <section className='about section' id='about'>
      <h2 className='section__title'>About Us</h2>
      <h3 className='section__subtitle'>3 fun facts about the Bride and Groom</h3>
      <div className='about__container container grid'>
      <div className='groom__info'>
          <h2>Patrick C</h2>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82 12" width="50" height="10">
            <g transform="translate(-8.2 -9.1)">
              <path style={{ fill: 'none', stroke: '#233819', strokeWidth: '2px' }} d="M49.2,15.1,14.929,20m0,0c-.3,0-.6.1-.8.1a5,5,0,1,1,4.925-5" transform="translate(0 0)"></path>
              <g transform="translate(49.2 10.1)">
                <path style={{ fill: 'none', stroke: '#233819', strokeWidth: '2px' }} d="M49,15.1l34.271-4.9m0,0c.3,0,.6-.1,.8-.1a5,5,0,1,1-4.925,5" transform="translate(-49 -10.1)"></path>
              </g>
            </g>
          </svg>
          <ul className='about__list'>
            <li>An Assistant Psychologist within a Children's Disability Network Team.</li>
            <li>Used to be an avid reader, can now barely get through a chapter!</li>
            <li>Fan of most sports. <MdOutlineSportsHockey className='about__icon'/></li>
          </ul>
        </div>
        <img src={aboutImage} alt='Profile' title='Profile' className='about__img' width="550" height="550"/>
        <div className='bride__info'>
          <h2>Jasmine K</h2>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82 12" width="50" height="10">
            <g transform="translate(-8.2 -9.1)">
              <path style={{ fill: 'none', stroke: '#233819', strokeWidth: '2px' }} d="M49.2,15.1,14.929,20m0,0c-.3,0-.6.1-.8.1a5,5,0,1,1,4.925-5" transform="translate(0 0)"></path>
              <g transform="translate(49.2 10.1)">
                <path style={{ fill: 'none', stroke: '#233819', strokeWidth: '2px' }} d="M49,15.1l34.271-4.9m0,0c.3,0,.6-.1,.8-.1a5,5,0,1,1-4.925,5" transform="translate(-49 -10.1)"></path>
              </g>
            </g>
          </svg>
          <ul className='about__list'>
            <li>Software Engineer, working for a tech company.</li>
            <li>Started off in the land of borscht and bears (Mother Russia),
              but I've been living in the Real Capital of Ireland for over a decade,
              so I'm practically part of the furniture!</li>
            <li>My goal is to visit 100 countries by the age of 35, still have 40 to go! <FaGrimace className='about__icon'/></li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default About;
